import { createGlobalStyles } from "goober/global";

export const GlobalStyle = createGlobalStyles`
  html {
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    margin: 0;
    padding: 0;

    font-family: F37 Bolton;
  }

  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }

  button {
    background: none;
    padding: 0;
    margin: 0;
    border: none;

    &:focus {
      outline: none;
    }
  }

`;
