var c = Object.defineProperty;
var u = (i, r, e) => r in i ? c(i, r, { enumerable: !0, configurable: !0, writable: !0, value: e }) : i[r] = e;
var o = (i, r, e) => u(i, typeof r != "symbol" ? r + "" : r, e);
class a {
  constructor() {
    o(this, "enabled");
    this.enabled = !0;
  }
  register(r) {
    typeof window > "u" || typeof window.Logger[r.moduleName] < "u" || (window.Logger[r.moduleName] = r);
  }
}
const d = {
  trace: 0,
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
  fatal: 5
};
class f extends a {
  constructor(e) {
    super();
    o(this, "moduleName");
    o(this, "labelCSS");
    o(this, "levelCSS");
    o(this, "logLevel");
    o(this, "Logger");
    this.moduleName = e.moduleName, this.labelCSS = `background-color: ${e.logColor}; font-size:8px; line-height: 16px; padding-right: 4px; padding-left: 8px; border-radius: 8px; border-bottom-left-radius: 8px; color: ${this.getTextColor(
      e.logColor
    )};`, this.levelCSS = "font-size:8px; line-height: 16px; padding-right: 8px; padding-left: 4px; border-top-right-radius: 8px; border-bottom-right-radius: 8px; font-weight: bold;", this.logLevel = d[e.defaultLevel || "info"], this.Logger = this.getLogger(), this.Logger.register(this);
  }
  getLogger() {
    const e = new a();
    return typeof window > "u" ? e : (window.Logger = e, window.Logger);
  }
  getTextColor(e) {
    e.slice(0, 1) === "#" && (e = e.slice(1)), e.length === 3 && (e = e.split("").map(function(n) {
      return n + n;
    }).join(""));
    const t = parseInt(e.substring(0, 2), 16), g = parseInt(e.substring(2, 2), 16), s = parseInt(e.substring(4, 2), 16);
    return (t * 299 + g * 587 + s * 114) / 1e3 >= 128 ? "black" : "white";
  }
  getLevelCSS(e) {
    return `${this.levelCSS}; color: ${e}; `;
  }
  getLoggerFn(e) {
    switch (e) {
      case "error":
      case "fatal":
        return console.error;
      case "warn":
        return console.warn;
      default:
        return console.log;
    }
  }
  log(e, t, g, s) {
    if (!this.enabled || this.logLevel > d[t]) return;
    const l = this.getLevelCSS(g), n = this.getLoggerFn(t);
    return typeof s > "u" ? n(
      `%c${this.moduleName} %c${t.toUpperCase()}`,
      this.labelCSS,
      l,
      e
    ) : n(
      `%c${this.moduleName} %c${t.toUpperCase()}`,
      this.labelCSS,
      l,
      e,
      s
    );
  }
  trace(e, t) {
    this.log(e, "trace", "DarkOrchid", t);
  }
  debug(e, t) {
    this.log(e, "debug", "grey", t);
  }
  info(e, t) {
    this.log(e, "info", "blue", t);
  }
  warn(e, t) {
    this.log(e, "warn", "Gold", t);
  }
  error(e, t) {
    this.log(e, "error", "FireBrick", t);
  }
  fatal(e, t) {
    this.log(e, "fatal", "Crimson", t);
  }
}
o(f, "logLevels", {
  trace: 0,
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
  fatal: 5
});
export {
  f as ModuleLogger
};
