import { jsx as n } from "react/jsx-runtime";
import { useCallback as m, useEffect as f } from "react";
/* empty css                    */
import { classes as p } from "../utils/classes.es.js";
const u = "d1ml0bto", y = ({
  children: e,
  id: s
}) => {
  const r = m((t) => {
    t && (t.popover = "manual", t.open = !0, t.showPopover(), t.classList.add("open"));
  }, []);
  return f(() => {
    if (typeof window > "u") return;
    const t = document.querySelectorAll(".toast");
    for (let o = t.length - 1; o >= 0; o--) {
      const a = t[o], l = Array.from(t).slice(o + 1).reduce((c, i) => c + i.clientHeight + 32, 32);
      a.style.bottom = `${l}px`;
    }
  }, []), /* @__PURE__ */ n("div", {
    ref: r,
    className: p("toast", u),
    id: s,
    children: e
  });
};
export {
  y as Toast
};
