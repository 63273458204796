import { ConsoleRecorder, ExplicitContext, Tracer } from "zipkin";
import wrapFetch from "zipkin-instrumentation-fetch";

export class ClientFetcher {
  protected fetcher: (
    url: RequestInfo,
    init?: RequestInit | undefined
  ) => Promise<Response>;

  constructor() {
    const ctxImpl = new ExplicitContext(); // the in-process context
    const recorder =
      process.env.NODE_ENV === "production"
        ? new ConsoleRecorder()
        : new ConsoleRecorder(() => void 0);
    const localServiceName = "mqlocate-pwa"; // name of this application
    const remoteServiceName = "mqlocate-rest";
    const tracer = new Tracer({
      ctxImpl,
      recorder: recorder,
      localServiceName,
      log: undefined
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fetcher = wrapFetch(fetch, { tracer, remoteServiceName });
  }
}
