export class ClientInterceptor {
  handlers: Array<(req: RequestInit) => Promise<RequestInit>>;

  constructor() {
    this.handlers = [];
  }

  register(fn: (req: RequestInit) => Promise<RequestInit>) {
    this.handlers.push(fn);
  }

  async apply(initConfig: RequestInit): Promise<RequestInit> {
    let newConfig = initConfig;
    for await (const fn of this.handlers) {
      newConfig = await fn(newConfig);
    }
    return newConfig;
  }
}
