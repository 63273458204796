import { convertParamsToQueryString } from "@machineq/components";
import type { ApiQueryParams, ApiRoutes } from "@machineq/models";

export type ApiRequestConfig<T> = {
  root: ApiRoutes;
  dynamic?: string | number | (string | number)[];
  queryString?: string;
  queryParams?: ApiQueryParams<T>;
  trailingSlash?: boolean;
};

export type ApiRequestError<T = string, Req = Record<string, unknown>> = {
  detail: T;
  error_code: "unexpected_error" | "validation_error" | "not_found" | "invalid";
  status_code: number;
  config: {
    data: Req;
  };
};
export type ApiRequestErrorDetail<T> = ApiRequestError<T>["detail"];

const parseApiRequestConfigDynamicParams = (
  dynamic: string | number | (string | number)[]
): string => {
  if (typeof dynamic === "string" || typeof dynamic === "number") {
    return dynamic?.toString();
  }
  return dynamic.join("/");
};

export function formatApiRequestUrlFromApiRequestConfig<
  ApiQueryParams = Record<string, unknown>
>({
  root,
  dynamic,
  queryString,
  queryParams,
  trailingSlash = true
}: ApiRequestConfig<ApiQueryParams> & { trailingSlash?: boolean }) {
  const slash = trailingSlash ? "/" : "";
  // Only query string
  if (!dynamic && !queryParams && queryString) {
    if (queryString.includes("?")) {
      return `${root}${slash}${queryString}`;
    }
    return `${root}${slash}?${queryString}`;
  }

  // Only query params
  if (!dynamic && queryParams && !queryString) {
    const queryParamsOnly = `${root}/${convertParamsToQueryString(
      queryParams
    )}`;
    return queryParamsOnly;
  }

  // dynamic and query string
  if (dynamic && !queryParams && queryString) {
    const dynamicAndQueryString = `${root}/${parseApiRequestConfigDynamicParams(
      dynamic
    )}${queryString}`;
    return dynamicAndQueryString;
  }

  // dynamic and query params
  if (dynamic && queryParams && !queryString) {
    const dynamicAndQueryParams = `${root}/${parseApiRequestConfigDynamicParams(
      dynamic
    )}${convertParamsToQueryString(queryParams)}`;
    return dynamicAndQueryParams;
  }

  // only dynamic
  if (dynamic && !queryParams && !queryString) {
    const dynamicOnly = `${root}/${parseApiRequestConfigDynamicParams(
      dynamic
    )}${slash}`;
    return dynamicOnly;
  }

  const rootRoute = `${root}${slash}`;
  return rootRoute;
}

/**
 * Functional is typeguard that checks to see
 * if a value is an ApiRequestError
 */
export function isApiRequestError(
  error: unknown | ApiRequestError
): error is ApiRequestError {
  return Object.hasOwn(error ?? {}, "detail");
}
