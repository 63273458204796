import { jsxs as v, Fragment as g, jsx as c } from "react/jsx-runtime";
import { useState as h, useRef as f, useEffect as N } from "react";
import { createPortal as O } from "react-dom";
import { Toast as T } from "./Toast.es.js";
import { toastContainerId as y, getToastOptions as l, deleteToastOptions as j } from "./toast.utils.es.js";
function S({
  ToastComponent: m,
  id: p
}) {
  const [a, u] = h({}), n = f(void 0), s = f(null);
  return N(() => {
    if (typeof window > "u" || !s.current) return;
    const r = (t) => {
      for (const e of t) {
        if (e.type !== "childList") return;
        if (e.addedNodes.length)
          for (const d of e.addedNodes) {
            const o = l(d);
            u((i) => ({
              ...i,
              [o.id]: o
            }));
          }
        if (e.removedNodes.length)
          for (const d of e.removedNodes) {
            const o = l(d);
            u((i) => {
              const { [o.id]: w, ...b } = i;
              return j(o.id), b;
            });
          }
      }
    };
    return n.current = new MutationObserver(r), console.log("Connecting mutation observer"), n.current.observe(s.current, {
      attributes: !0,
      childList: !0,
      subtree: !0
    }), () => {
      var t;
      console.log("Disconnecting mutation observer"), (t = n.current) == null || t.disconnect();
    };
  }, []), /* @__PURE__ */ v(g, { children: [
    /* @__PURE__ */ c(
      "div",
      {
        id: p ?? y,
        style: { display: "none" },
        ref: s
      }
    ),
    Object.values(a).length > 0 && O(
      Object.entries(a).map(([r, t]) => /* @__PURE__ */ c(T, { id: r, children: /* @__PURE__ */ c(m, { ...t }) }, r)),
      document.body
    )
  ] });
}
export {
  S as Toaster
};
