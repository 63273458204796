const i = "toast-container", n = () => (window.buttery_toasts || (window.buttery_toasts = /* @__PURE__ */ new Map()), window.buttery_toasts), p = (t) => {
  const o = t.id, e = n().get(o);
  if (!e)
    throw new Error(
      `Cannot find options for "${o}". This should not have happened. Please log an issue.`
    );
  return {
    id: o,
    ...e
  };
}, r = (t) => {
  const s = n();
  console.log("Deleting toast options", t), s.delete(t);
}, d = (t) => {
  const s = n(), o = window.crypto.randomUUID();
  return s.set(o, t), o;
};
export {
  r as deleteToastOptions,
  p as getToastOptions,
  n as getToasterOptionsMap,
  d as setToastOptions,
  i as toastContainerId
};
