import { jsxs as a, jsx as l } from "react/jsx-runtime";
import { forwardRef as t, memo as d } from "react";
const i = ({ title: e, titleId: o, ...r }, n) => /* @__PURE__ */ a(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: n,
    "aria-labelledby": o,
    ...r,
    children: [
      e ? /* @__PURE__ */ l("title", { id: o, children: e }) : null,
      /* @__PURE__ */ a("g", { fill: "none", fillRule: "evenodd", children: [
        /* @__PURE__ */ l(
          "path",
          {
            fill: "currentColor",
            d: "M4.22 4.22a.75.75 0 0 1 1.06 0l6.743 6.742 6.743-6.742a.75.75 0 0 1 .976-.073l.085.073a.75.75 0 0 1 0 1.06l-6.743 6.743 6.743 6.743a.75.75 0 0 1 .072.976l-.072.085a.75.75 0 0 1-1.061 0l-6.743-6.743-6.743 6.743a.75.75 0 0 1-.976.072l-.084-.072a.75.75 0 0 1 0-1.061l6.742-6.743L4.22 5.28a.75.75 0 0 1-.073-.976Z"
          }
        ),
        /* @__PURE__ */ l("path", { d: "M0 0h24v24H0z" })
      ] })
    ]
  }
), s = t(i), m = d(s), c = m;
export {
  c as default
};
