import type { AppState } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useCallback } from "react";

import { getRuntimeVariable } from "@pwa/lib/env";

export const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const { replace } = useRouter();

  const onRedirectCallback = useCallback<
    (appState: AppState | undefined) => void
  >(
    (appState) => {
      replace(appState?.returnTo || "/");
    },
    [replace]
  );

  return (
    <Auth0Provider
      domain={getRuntimeVariable("PWA_BROWSER_AUTH_0_DOMAIN")}
      clientId={getRuntimeVariable("PWA_BROWSER_AUTH_0_CLIENT_ID")}
      authorizationParams={{
        audience: getRuntimeVariable("PWA_BROWSER_AUTH_0_AUDIENCE"),
        redirect_uri:
          typeof window !== "undefined"
            ? `${getRuntimeVariable("PWA_BROWSER_APP_HOSTNAME")}/callback`
            : undefined
      }}
      cacheLocation={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof window !== "undefined" && typeof window.Cypress !== "undefined"
          ? "localstorage"
          : "memory"
      }
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};
