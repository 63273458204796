function s(...o) {
  const i = [];
  for (let e = 0; e < o.length; e++) {
    const n = o[e];
    if (n) {
      if (typeof n == "string" || typeof n == "number") {
        i.push(n.toString());
        continue;
      }
      if (Array.isArray(n)) {
        if (n.length) {
          const t = s(...n);
          t && i.push(t);
        }
        continue;
      }
      if (typeof n == "object")
        for (const t in n)
          n[t] && i.push(t);
    }
  }
  return i.join(" ");
}
export {
  s as classes
};
