// Variables to be supplied to the browser as well as node
// TODO: Update script to dynamically create these based upon the .env file when developing locally
type BrowserVariablesApp =
  | "PWA_BROWSER_APP_HOSTNAME"
  | "PWA_BROWSER_API_HOSTNAME";
type BrowserVariablesMapbox =
  | "PWA_BROWSER_MAPBOX_STYLE"
  | "PWA_BROWSER_MAPBOX_TOKEN";
type BrowserVariablesGoogleMaps =
  | "PWA_BROWSER_GOOGLE_MAPS_GEOLOCATION_API_KEY"
  | "PWA_BROWSER_GOOGLE_MAPS_TIMEZONE_API_KEY";
type BrowserVariablesSentry =
  | "PWA_BROWSER_COMMIT_SHA"
  | "PWA_BROWSER_SENTRY_DSN"
  | "PWA_BROWSER_SERVER_ROOT_DIR"
  | "PWA_BROWSER_SENTRY_ENV"
  | "PWA_BROWSER_SENTRY_DISABLED";
type BrowserVariablesAuth0 =
  | "PWA_BROWSER_AUTH_0_DOMAIN"
  | "PWA_BROWSER_AUTH_0_CLIENT_ID"
  | "PWA_BROWSER_AUTH_0_AUDIENCE";

type BrowserVariables =
  | BrowserVariablesApp
  | BrowserVariablesMapbox
  | BrowserVariablesSentry
  | BrowserVariablesAuth0
  | BrowserVariablesGoogleMaps;

// Variables to be supplied to just node
type ServerVariablesSentry =
  | "PWA_SENTRY_ORG"
  | "PWA_SENTRY_PROJECT"
  | "PWA_SENTRY_AUTH_TOKEN";
type ServerVariables = ServerVariablesSentry;

export type RuntimeEnvironmentVariables =
  | BrowserVariables
  | ServerVariables
  | ServerVariablesSentry;

type RuntimeEnvironmentVariableOptions = {
  /**
   * Adding this option will ignore the actual value
   * of the environment variable and will instead check
   * to see if any value exists. If it does exist, it will
   * return a string of "true". If it doesn't exist, it
   * will return a string of "false".
   *
   * @returns "true" | "false"
   */
  verifyExistence?: boolean;
};

/**
 * This is an isomorphic function that returns the
 * matched with the name of the environment variable that
 * has been provided as an argument.
 *
 * It will check to see if the variable should be fetched from the browser or
 * the node process. If it doesn't find it where it should be, a
 * "RUNTIME_VAR_NOT_FOUND_REPLACE_ME" will be returned.
 */
export const getRuntimeVariable = (
  environmentVar: RuntimeEnvironmentVariables,
  options?: RuntimeEnvironmentVariableOptions
): string | "true" | "false" => {
  // Variable should be available on the browsers window object
  if (typeof window !== "undefined" && environmentVar.includes("PWA_BROWSER")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.env[environmentVar];
  }

  if (typeof window !== "undefined" && options?.verifyExistence) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.env[environmentVar] ? "true" : "false";
  }

  // variable should exist on the node process
  if (
    typeof window === "undefined" &&
    typeof process.env[environmentVar] !== "undefined"
  ) {
    return process.env[environmentVar] as string;
  }

  if (typeof window === "undefined" && options?.verifyExistence) {
    return typeof process.env[environmentVar] ? "true" : "false";
  }

  return "RUNTIME_VAR_NOT_FOUND_REPLACE_ME";
};
