import { makeColor, makeRem } from "@machineq/theme";
import { styled } from "goober";
import { forwardRef, useCallback, useEffect, useMemo } from "react";

import { Picture } from "@pwa/components/image";

import { debugPage } from "./page.utils";

const SPageLoader = styled("div")`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${makeColor("primary-dark")};
  z-index: 1000000;
  opacity: 1;
  transition:
    opacity 0.75s ease-out,
    z-index 0.75s ease-in-out;

  &.hide {
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }
`;

const id = "page-loader";

const StyledSpan = styled("span")`
  color: #fff;
  text-align: center;
  display: block;
  margin-top: ${makeRem(48)};
  font-family: inherit;
`;

export const PageLoader = forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements["div"] & {
    mqMessage?: string;
    mqShouldDisplay?: boolean;
  }
>(function PageLoader(props, ref) {
  const shouldDisplay = props.mqShouldDisplay ?? true;
  if (!shouldDisplay) return null;
  const { mqShouldDisplay: _, ...restProps } = props;
  return (
    <SPageLoader {...restProps} ref={ref} id={id}>
      <div>
        <Picture
          fileName="mq-wordmark-white"
          alt="machine-q-wordmard"
          align="center"
          width={220}
          height="auto"
        />
        <StyledSpan>{restProps.mqMessage || "Loading..."}</StyledSpan>
      </div>
    </SPageLoader>
  );
});

export const usePageLoader = () => {
  const hideLoader = useCallback(() => {
    const pageLoaderEl = document.getElementById(id);
    if (!pageLoaderEl || pageLoaderEl.classList.contains("hide")) return;
    debugPage.debug("Hiding page loader");
    pageLoaderEl.classList.add("hide");
  }, []);

  return useMemo(
    () => ({
      hideLoader
    }),
    [hideLoader]
  );
};

/**
 * A utility that takes in an any number of boolean arguments
 * that when all are evaluated to true, the hideLoader function
 * is run.
 *
 * So basically when all conditions are met, the loader will disappear
 */
export const useHidePageLoader = (...deps: boolean[]) => {
  const { hideLoader } = usePageLoader();
  useEffect(() => {
    const shouldHide = deps.reduce((accum, dep) => {
      if (dep) return true;
      return accum;
    }, false);

    if (!shouldHide) return;
    hideLoader();
  }, [deps, hideLoader]);

  return hideLoader;
};
