import { jsxs as s, jsx as e } from "react/jsx-runtime";
import { forwardRef as t, memo as h } from "react";
const i = ({ title: o, titleId: r, ...l }, m) => /* @__PURE__ */ s(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: m,
    "aria-labelledby": r,
    ...l,
    children: [
      o ? /* @__PURE__ */ e("title", { id: r, children: o }) : null,
      /* @__PURE__ */ e(
        "path",
        {
          fill: "currentColor",
          d: "M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"
        }
      )
    ]
  }
), c = t(i), n = h(c), w = n;
export {
  w as default
};
