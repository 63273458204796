import { useEffect } from "react";

import { useAccessToken } from "@pwa/features/auth";
import { getRuntimeVariable } from "@pwa/lib/env";

import { ApiClient } from "./api.Clientx";

const apiClient = new ApiClient({
  domain: `${getRuntimeVariable("PWA_BROWSER_API_HOSTNAME")}/api`,
  version: "v1.0"
});

export const useApi = () => {
  const getAccessToken = useAccessToken();

  useEffect(() => {
    apiClient.interceptors.request.register(async (requestConfig) => {
      const accessToken = await getAccessToken();
      requestConfig.headers = {
        ...requestConfig.headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : ""
      };
      return requestConfig;
    });
  }, [getAccessToken]);

  return apiClient;
};
