import { jsx as s } from "react/jsx-runtime";
import { styled as f } from "@linaria/react";
import { makeRem as y, makeColor as q } from "@machineq/theme";
import u from "clsx";
import { forwardRef as p, useMemo as x, memo as A } from "react";
/* empty css                   */
const C = /* @__PURE__ */ f("div")({
  name: "SIcon",
  class: "s1jw838t",
  propsAsIs: !1
}), b = p(function(o, n) {
  var t;
  const i = o.Icon, r = x(() => {
    if (o.mqAccessibility === "decorative") {
      const {
        style: j,
        mqAccessibility: w,
        Icon: k,
        ...d
      } = o;
      return {
        ...d,
        "aria-hidden": !0,
        role: "img"
      };
    }
    const {
      style: P,
      mqAccessibility: S,
      Icon: h,
      mqTitle: c,
      ...a
    } = o;
    return {
      ...a,
      title: c,
      titleId: c.split(" ").join("_")
    };
  }, [o]), {
    mqSize: m = 24,
    mqColor: e,
    className: l,
    ...I
  } = r;
  return /* @__PURE__ */ s(C, {
    style: {
      fontSize: y(m),
      zIndex: (t = o.style) == null ? void 0 : t.zIndex,
      ...e ? {
        color: q(e)
      } : {}
    },
    className: u(l, "c-icon"),
    children: /* @__PURE__ */ s(i, {
      ...I,
      ref: n
    })
  });
}), T = A(b);
export {
  T as Icon,
  b as IconFC
};
