import type { FC, ReactNode } from "react";
import { SWRConfig } from "swr";

import { useApi } from "./api.useApi";

export const SWRProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const api = useApi();

  return (
    <SWRConfig
      value={{
        fetcher: async (url: string) => {
          const data = await api.get(url);
          return data;
        },
        // 5 minutes
        refreshInterval: 300000,
        shouldRetryOnError: false,
        revalidateOnFocus: process.env.NODE_ENV === "production"
      }}
    >
      {children}
    </SWRConfig>
  );
};
