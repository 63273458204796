const h = (e) => e.reduce((n, r) => ({ ...n, [r]: `.${r}` }), {}), l = "app", m = {
  "dark-blue": "#00144e",
  "mid-blue": "#0237cc",
  "mq-blue": "#0066ff",
  "light-blue": "#3d8bff",
  "mq-orange": "#ff471c",
  "digital-orange": "#fe820d",
  "digital-green": "#1dfeb0",
  salmon: "#fc5959",
  black: "#000000",
  "mq-black": "#282725",
  "grey-5": "#6b6b6b",
  "grey-4": "#999999",
  "grey-3": "#c8c8c8",
  "grey-2": "#efefef",
  "grey-1": "#f7f7f7",
  white: "#ffffff",
  danger: "#e0263a",
  warning: "#ffc500",
  success: "#2fb17f"
}, b = {
  thin: 300,
  light: 300,
  regular: 400,
  bold: 700
}, y = {
  mobile: 0,
  tablet: 577,
  desktop: 992
}, f = (e) => {
  const t = e.substring(1);
  if (t.length != 6)
    throw "Only six-digit hex colors are allowed.";
  const n = t.match(/.{1,2}/g);
  return n ? `${parseInt(n[0], 16)}, ${parseInt(
    n[1],
    16
  )}, ${parseInt(n[2], 16)}` : "ERROR_HEX_TO_RGB";
}, g = (e) => `--color-${l}-${e}`, u = (e) => `--color-${l}-${e}-rgb`, $ = (e) => `var(${g(e)})`, k = (e) => b[e], o = (e) => `${e}px`;
function w(e) {
  return `${e / 16}rem`;
}
const a = 0, s = o(576), c = o(577), i = o(991), p = o(992), d = (e) => {
  switch (e) {
    case "mobile-only":
      return `@media only screen and (max-width: ${s})`;
    case "tablet-only":
      return `@media only screen and (min-width: ${c}) and (max-width: ${i})`;
    case "desktop-only":
    case "desktop-up":
      return `@media only screen and (min-width: ${p})`;
    case "mobile-up":
      return `@media only screen and (min-width: ${a})`;
    case "tablet-up":
    case "tablet-to-desktop":
      return `@media only screen and (min-width: ${c})`;
    case "mobile-to-tablet":
      return `@media only screen and (min-width: ${a}) and (max-width: ${s})`;
    case "mobile-to-desktop":
      return `@media only screen and (min-width: ${a}) and (max-width: ${i})`;
    default:
      return "THIS IS NOT A VALUE";
  }
}, x = d("mobile-to-desktop"), R = d("desktop-up"), C = (e, t) => `rgba(var(${u(e)}), ${t})`, S = (e, t) => `rgba(${f(m[e])}, ${t})`, T = (e) => {
  switch (e) {
    case "body":
      return "var(--font-family)";
    case "heading":
      return "var(--font-family)";
  }
}, q = "themeStyles_t1upbwhb", v = "fontStyles_f6jozzz";
export {
  l as appColorVarPrefix,
  y as breakpointMap,
  R as desktopStyles,
  v as fontStyles,
  h as makeClass,
  $ as makeColor,
  T as makeFontFamily,
  k as makeFontWeight,
  f as makeHexToRgb,
  o as makePx,
  w as makeRem,
  d as makeResponsiveMedia,
  C as makeRgba,
  S as makeThemeColorRgba,
  x as mobileStyles,
  b as mqFontWeights,
  m as mqThemeColors,
  q as themeStyles
};
